import getEnv from '@/util/env'
import EventBus from '@/util/EventBus.js'
import { PhoneProfileInfo } from '@/models/whatsapp/PhoneProfileInfo.js'
import PhoneProfileInfoService from '@/services/whatsapp/phone-profile-info.service.js'
import RoutesService from '@/services/whatsapp/routes.service.js'
import { Route } from '@/models/whatsapp/Route.js'
import WhatsappDataService from '@/services/whatsapp-data-service'

export default {
  name: 'PhoneProfileInfo',
  components: { },
  data () {
    return {
      phoneId: null,
      msisdn: null,
      phoneProfileInfo: new PhoneProfileInfo(),
      route: new Route(),
    }
  },
  computed: {
    businessCategories: function () {
      return [
        { text: this.$t('Automoción').toString(), value: 1 },
        { text: this.$t('Belleza, spa y peluquería').toString(), value: 2 },
        { text: this.$t('Ropa y complementos').toString(), value: 3 },
        { text: this.$t('Educación').toString(), value: 4 },
        { text: this.$t('Entretenimiento').toString(), value: 5 },
        { text: this.$t('Planificación y servicio de eventos').toString(), value: 6 },
        { text: this.$t('Finanzas y banca').toString(), value: 7 },
        { text: this.$t('Alimentos y comestibles').toString(), value: 8 },
        { text: this.$t('Servicio público').toString(), value: 9 },
        { text: this.$t('Hotel y alojamiento').toString(), value: 10 },
        { text: this.$t('Medicina y salud').toString(), value: 11 },
        { text: this.$t('Organización sin ánimo de lucro').toString(), value: 12 },
        { text: this.$t('Servicios profesionales').toString(), value: 13 },
        { text: this.$t('Compras y ventas minoristas').toString(), value: 14 },
        { text: this.$t('Viajes y transportes').toString(), value: 15 },
        { text: this.$t('Restaurante').toString(), value: 16 },
        { text: this.$t('Otra').toString(), value: 17 },
      ]
    },
    selectedBusinessCategory () {
      const found = this.businessCategories.find((category) => category.value === this.phoneProfileInfo.businessCategory)
      return found ? found.text : ''
    },
  },
  methods: {
    getCurrentUserRoute () {
      RoutesService.getLineByMsisdn({ msisdn: this.msisdn })
      .then((route) => {
        this.route.loadData(route)
      })
    },
    loadProfile () {
      PhoneProfileInfoService.getPhoneProfileByMsisdn({ msisdn: this.msisdn }).then((response) => {
        this.phoneProfileInfo.loadData(response)
      })
    },
    store () {
      if (this.phoneProfileInfo.id) {
        this.update()
      } else {
        this.save()
      }
    },
    save () {
      PhoneProfileInfoService.saveByMsisdn({
        phoneId: this.phoneId,
        msisdn: this.msisdn,
        phoneProfileInfo: this.phoneProfileInfo,
      }).then((response) => {
        this.phoneProfileInfo.id = response.id
        EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado correctamente la información de perfil').toString())
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Ocurrio un error al guardar la información del perfil').toString())
      })
      .finally(() => {
        const data = {
          user_id: this.route.userId,
          phone_id: this.route.phoneId,
          msisdn: this.route.msisdn,
          ...this.phoneProfileInfo.getUpdateProfileInfoData(),
        }
        WhatsappDataService.setProvider(this.route.route_partner.provider_name)
        WhatsappDataService.updateProfile(data)
      })
    },
    update () {
      PhoneProfileInfoService.update({
        phoneId: this.phoneId,
        msisdn: this.msisdn,
        phoneProfileInfo: this.phoneProfileInfo,
      }).then((response) => {
        EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado correctamente la información de perfil').toString())
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Ocurrio un error al guardar la información del perfil').toString())
      })
      .finally(() => {
        const data = {
          user_id: this.route.userId,
          phone_id: this.route.phoneId,
          msisdn: this.route.msisdn,
          ...this.phoneProfileInfo.getUpdateProfileInfoData(),
        }
        WhatsappDataService.setProvider(this.route.routePartner.provider_name)
        WhatsappDataService.updateProfile(data)
      })
    },
    showMultimedia (currentRootDirectory) {
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
    selectFile (url, fileInfo) {
      console.log('fileInfo: {}', fileInfo)
      if ((fileInfo.width > 640 || fileInfo.height > 640) || (fileInfo.width < 192 || fileInfo.height < 192)) {
        EventBus.$emit('showAlert', 'danger', this.$t('La imagen tener un tamaño entre 192x192 y 640x640').toString())
      } else {
        this.phoneProfileInfo.brandImageUrl = `${getEnv('backend_assets_url')}/${url}`
        this.phoneProfileInfo.brandImageMime = fileInfo.mimeType
      }
    },
    deleteImage () {
      this.phoneProfileInfo.brandImageUrl = ''
      this.phoneProfileInfo.brandImageMime = ''
    },
  },
  mounted () {
    this.phoneId = this.$route.query.phone_id
    this.msisdn = this.$route.query.msisdn
    this.loadProfile()
    this.getCurrentUserRoute()
    EventBus.$on('multimediaSelected', (url, type, fileId, name, fileInfo) => {
      this.selectFile(url, fileInfo)
    })
  },
}
